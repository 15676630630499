import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Theme } from '@radix-ui/themes';
import { Box, Container, Flex } from '@radix-ui/themes';

import Navbar from './components/Navbar';
import SearchForm from './components/SearchForm';
import Results from './components/Results';
import NoPage from './components/NoPage';

function App() {

  return (
    <Theme appearance="dark">
      <Box style={{ background: 'var(--gray-a2)', borderRadius: 'var(--radius-3)' }} minHeight="100vh">
        <Flex style={{ alignItems: 'center', justifyContent: 'center'}}>
          <Container maxWidth={{ initial: '350px', sm: '450px' }}>
            <Navbar />
            <BrowserRouter>
              <Routes>
                <Route index element={<SearchForm />} />
                <Route path="/results" element={<Results />} />
                <Route path="*" element={<NoPage />} />
              </Routes>
            </BrowserRouter>
          </Container>
        </Flex>
      </Box>
    </Theme>
  );
}

export default App;
