import { Heading, Box, Card, Text } from '@radix-ui/themes';

export default function Navbar() {
  return (
    <Box style={{ marginTop: 'var(--space-4)', marginBottom: 'var(--space-4)' }}>
      <Heading align='center' style={{ paddingTop: 'var(--space-4)' }} color='indigo'>
        RandoResto
      </Heading>
      <Text as="div" size="2" weight="bold" align='center' style={{ paddingBottom: 'var(--space-4)' }}>
        Randomly choose walking-distance restaurants
      </Text>
    </Box>
  )
}