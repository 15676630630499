import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Box, Card, Text, Inset, Strong, Flex, Button, Grid, Badge } from '@radix-ui/themes';  
import noImg from '../assets/img/no_image.jpg';

export default function Results(){
  const { state } = useLocation();
  const [count, setCount] = useState(0);
  const navigate = useNavigate(); 

  const routeChange = () =>{ 
    navigate('/');
  }

  const decrementCount = () => {
    if (count > 0) setCount(count - 1);
  }

  const incrementCount = () => {
    if (count < state.length-1) setCount(count + 1);
  }

  return(
    <>
      <Card>
        <Flex style={{ alignContent: 'center', justifyContent: 'center' }}>
          <Box maxWidth="350px">
            <Card size="2">
              <Inset clip="padding-box" side="top" pb="current">
                <img
                  src={state[count]['image_url'] ? (state[count]['image_url']) : (noImg)}
                  alt={state[count]['alias']}
                  style={{
                    display: 'block',
                    objectFit: 'cover',
                    width: '100%',
                    height: 200,
                    backgroundColor: 'var(--gray-5)',
                  }}
                />
              </Inset>
              <Badge size="2" color="indigo" variant='outline'>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M7.22303 0.665992C7.32551 0.419604 7.67454 0.419604 7.77702 0.665992L9.41343 4.60039C9.45663 4.70426 9.55432 4.77523 9.66645 4.78422L13.914 5.12475C14.18 5.14607 14.2878 5.47802 14.0852 5.65162L10.849 8.42374C10.7636 8.49692 10.7263 8.61176 10.7524 8.72118L11.7411 12.866C11.803 13.1256 11.5206 13.3308 11.2929 13.1917L7.6564 10.9705C7.5604 10.9119 7.43965 10.9119 7.34365 10.9705L3.70718 13.1917C3.47945 13.3308 3.19708 13.1256 3.25899 12.866L4.24769 8.72118C4.2738 8.61176 4.23648 8.49692 4.15105 8.42374L0.914889 5.65162C0.712228 5.47802 0.820086 5.14607 1.08608 5.12475L5.3336 4.78422C5.44573 4.77523 5.54342 4.70426 5.58662 4.60039L7.22303 0.665992Z" fill="currentColor"></path></svg> 
                {state[count]['rating']}/5
              </Badge>
              <Text as="p" size="3">
                <Strong>{state[count]['name']}</Strong>
              </Text>
              <Text as="p" size="1">
                {state[count]['phone']}
              </Text>
              <Text as="p" size="1">
                {state[count]['location']['address1']}
              </Text>
              <Text as="p" size="1">
                {Math.floor(state[count]['distance'])}km
              </Text>
            </Card>
          </Box>
        </Flex>
      </Card>
      <Grid columns="2" gap="2" rows="repeat(2, 64px)" width="auto">
        <Flex gap='2' justify='start' style={{ marginTop: 'var(--space-4)' }}>
          <Button variant="soft" onClick={routeChange} size='3'>
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 6.5C10 8.433 8.433 10 6.5 10C4.567 10 3 8.433 3 6.5C3 4.567 4.567 3 6.5 3C8.433 3 10 4.567 10 6.5ZM9.30884 10.0159C8.53901 10.6318 7.56251 11 6.5 11C4.01472 11 2 8.98528 2 6.5C2 4.01472 4.01472 2 6.5 2C8.98528 2 11 4.01472 11 6.5C11 7.56251 10.6318 8.53901 10.0159 9.30884L12.8536 12.1464C13.0488 12.3417 13.0488 12.6583 12.8536 12.8536C12.6583 13.0488 12.3417 13.0488 12.1464 12.8536L9.30884 10.0159Z" fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path></svg>
            Search
          </Button>
        </Flex>
        <Flex gap='2' justify='end' style={{ marginTop: 'var(--space-4)' }}>
          <Button onClick={decrementCount} disabled={count < 1} size='3'>
            Previous
          </Button>
          <Button onClick={incrementCount} disabled={count+1 === state.length} size='3'>
            Next
          </Button>
        </Flex>
      </Grid>
    </>
  )
}